<template>
  <div class="block">
    <el-page-header @back="dynamic" content="部门配置" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text" @click="outerVisible = true">
        <el-button type="primary" size="mini">新增</el-button>
      </el-button>

      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form :model="form">
          <el-form-item label="部门名称" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.depName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="部门领导" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.depManager" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.remarks" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <el-dialog
            width="20%"
            title="警告"
            :visible.sync="innerVisible"
            append-to-body>
          <span>您将新增一条记录，请确认！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="resetForm();innerVisible = false;outerVisible = false;">确 定</el-button>
          </span>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <el-button @click="outerVisible = false">取 消</el-button>
          <el-button type="primary" @click="innerVisible = true">提交</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
        :data="depData"
        style="width: 100%; margin: auto"
        :cell-style="{padding: 0}"
        height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="depName"
          label="部门名称"
          width="220">
      </el-table-column>
      <el-table-column
          prop="depManager"
          label="部门领导"
          width="200">
      </el-table-column>
      <el-table-column
          prop="createDate"
          label="创建日期"
          width="120">
      </el-table-column>
      <el-table-column
          prop="remarks"
          label="备注">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope1">
          <el-button
              @click.native.prevent="open(scope1.row.depId)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button
              @click.native.prevent="queryDepById(scope1.row.depId);"
              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="outerEdit">
      <el-form :model="editForm">
        <el-form-item label="部门名称" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="editForm.depName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门领导" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="editForm.depManager" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="editForm.remarks" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <el-dialog
          width="20%"
          title="警告"
          :visible.sync="innerEdit"
          append-to-body>
        <span>修改信息，请确认！</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="innerEdit = false">取 消</el-button>
            <el-button type="primary" @click="editDep();innerEdit = false;outerEdit = false;">确 定</el-button>
          </span>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerEdit = false">取 消</el-button>
        <el-button type="primary" @click="innerEdit = true">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "department",
  data() {
    return {
      innerEdit: false,
      outerEdit: false,
      outerVisible: false,
      innerVisible: false,
      form: {
        depName: '',
        depManager: '',
        remarks: ''
      },
      editForm: {
        depName: '',
        depManager: '',
        remarks: '',
        createDate: ''
      },
      formLabelWidth: '120px',
      depData:[]
    }
  },
  mounted() {
    this.getDepData()
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    getDepData() {
      this.$http({
        method: 'get',
        url: '/api/dep/getDepList',
        data: []
      }).then((res)=>{
        this.depData = res.data.data
      })
    },
    resetForm() {
      this.$http({
        method: 'post',
        url: '/api/dep/insertDep',
        data: this.form
      }).then((res)=>{
        alert(res.data.msg)
        if (res.data.status) {
          this.form = {}
          this.getDepData()
        }
      })
    },
    queryDepById(depId) {
      this.$http({
        method: 'get',
        url: '/api/dep/queryDepById/' + depId,
        data: ''
      }).then((res) =>{
        this.editForm = res.data.data
        this.outerEdit = true
      })
    },
    open(depId) {
      this.$confirm('删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'delete',
          url: '/api/dep/deleteDepById/' +depId
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.getDepData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      });
    },
    editDep() {
      this.$http({
        method: 'put',
        url: '/api/dep/editDepById',
        data: this.editForm
      }).then((res) =>{
        this.$message({
          type: 'success',
          message: res.data.msg
        });
        this.getDepData()
      }).catch((res)=>{
        this.$message({
          type: 'info',
          message: res.data.msg
        });
      })
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>